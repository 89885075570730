import moment from 'moment-timezone';

export const daysFrom = (startDate, endDate) => {
  if (endDate) {
    return moment(endDate).diff(moment(startDate), 'days') + 1;
  }
  return moment().diff(moment(startDate), 'days') + 1;
};

export const format = (date, formatString) => {
  if (!date) return '';
  return moment.utc(date).tz('Asia/Seoul').format(formatString || 'YYYY-MM-DD');
};

export const formatDateTime = (date, formatString) => {
  if (!date) return '';
  return moment.utc(date).tz('Asia/Seoul').format(formatString || 'YYYY-MM-DD HH:mm');
};
